import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Load from './Load';
import { RoomProvider } from './context';
import App from './App';


ReactDOM.render(
   <RoomProvider>
    <Router>
      <App />
     </Router>
  </RoomProvider>

  
  ,
  document.getElementById('root')
);



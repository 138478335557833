import React from "react";
import './App.css';

function App() {
    return (
        <div>
            Coming soon
        </div>
    );
}

export default App;

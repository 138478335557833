import React, { Component } from 'react'
import Title from './Title'
import { FaRegMoneyBillAlt, FaHiking, FaShuttleVan, FaHotel } from 'react-icons/fa'
export default class Services extends Component {
    state = {
        services: [
            {
                icons: <FaRegMoneyBillAlt />,
                title: "Rent out your unit",
                info: "Coming Soon"
            },
            {
                icons: <FaHiking />,
                title: "Activities For Your Getaway",
                info: 'Coming Soon'
            },
            {
                icons: <FaShuttleVan />,
                title: "AirPort Transfer Service",
                info: "Coming Soon"
            },
            {
                icons: <FaHotel />,
                title: "Special Unit Requests",
                info: "Coming Soon"
            }
        ]
    }
    render() {
        return (
            <section className="services">
                <Title title="services" />
                <div className="services-center">
                    {this.state.services.map((item, index) => {
                        return <article key={index} className="service">
                            <span>{item.icons}</span>
                            <h6>{item.title}</h6>
                            <p>{item.info}</p>
                        </article>

                    })}
                </div>
            </section>
        )
    }
}
